import React, { useEffect, useState } from "react"
import newsStyles from "./news.module.scss"
import _get from "lodash/get"

export default function News() {
  const [posts, setPosts] = useState([])

  const axios = require("axios").default

  useEffect(() => {
    axios
      .get(
        `https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,media_type&access_token=${process.env.GATSBY_INSTAGRAM_TOKEN}&limit=7`
      )
      .then(function (response) {
        setPosts(_get(response, "data.data"))
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  return (
    <div id="posts" className={newsStyles.container}>
      <div className={newsStyles.content}>
        <h2>ÚLTIMAS PUBLICACIONES</h2>
        <div className={newsStyles.posts}>
          {posts.map((item, i) => {
            if (item.media_type === "VIDEO") {
              return (
                <div className={newsStyles.post} key={`post-${i}`}>
                  <iframe
                    className={newsStyles.img}
                    src={item.media_url}
                    frameborder="0"
                    width="300px"
                    height="300px"
                  ></iframe>
                  <div className={newsStyles.middle}>
                    <a
                      className={newsStyles.text}
                      href={item.permalink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Ver publicación
                    </a>
                  </div>
                </div>
              )
            } else {
              return (
                <div className={newsStyles.post} key={`post-${i}`}>
                  <img
                    className={newsStyles.img}
                    src={item.media_url}
                    alt={`Post instagram ${i}`}
                    width="300px"
                    height="300px"
                    title={`insta-video-${i}`}
                  />
                  <div className={newsStyles.middle}>
                    <a
                      className={newsStyles.text}
                      href={item.permalink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Ver publicación
                    </a>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}
