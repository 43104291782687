import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import contactStyles from "./contact.module.scss"
import whatsapp from "../../assets/img/whatsapp.svg"

export default function Contact() {
  const data = useStaticQuery(graphql`
    query Image {
      location: file(relativePath: { eq: "location.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      locationTwo: file(relativePath: { eq: "location-2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image: file(relativePath: { eq: "contact-right-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      fluid={data.image.childImageSharp.fluid}
      backgroundColor={`#ffffff`}
    >
      <div id="contacto" className={contactStyles.container}>
        <div className={contactStyles.info}>
          <div className={contactStyles.locationContainer}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7951.583310206693!2d-75.6888981!3d4.805799!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38873c406e9065%3A0x972a713b8f97776f!2sGYROS%20Y%20BARBQ%20-%20PEREIRA!5e0!3m2!1ses!2sco!4v1612912218942!5m2!1ses!2sco"
              width="600"
              height="450"
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
          <div className={contactStyles.row}>
            <div>
              <h3>Ubicación</h3>
              <p>
                Mall La Terraza, Avenida Circunvalar #4-63 local 7 <br />
                Pereira, Risaralda <br />
                Colombia
              </p>
            </div>
            <div>
              <h3>Horario</h3>
              <p>Lun - Dom, 11 am - 11 pm</p>
            </div>
            <div>
              <h3>Contacto</h3>
              <p>
                <a href="tel:573233450951">+57 3233450951</a>
              </p>
              <p>
                <a
                  href="573233450951"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Escríbenos{" "}
                  <img
                    className={contactStyles.whatsapp}
                    src={whatsapp}
                    alt="Whatsapp"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className={contactStyles.info}>
          <div className={contactStyles.locationContainer}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7951.52100976785!2d-75.7936183!3d4.8111355!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e387d0280a6f771%3A0xa6d26840c32239ed!2sGarden%20Mall%20Cerritos!5e0!3m2!1ses-419!2sco!4v1612912338700!5m2!1ses-419!2sco"
              width="600"
              height="450"
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
          <div className={contactStyles.row}>
            <div>
              <h3>Ubicación</h3>
              <p>
                Garden Mall Cerritos Local 1, Frente a Uniminuto, Barrio Galicia{" "}
                <br />
                Pereira, Risaralda <br />
                Colombia
              </p>
            </div>
            <div>
              <h3>Horario</h3>
              <p>Lun - Dom, 11 am - 9 pm</p>
            </div>
            <div>
              <h3>Contacto</h3>
              <p>
                <a href="tel:573233450951">+57 3233450951</a>
              </p>
              <p>
                <a
                  href="http://wa.me/+573233450951"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Escríbenos{" "}
                  <img
                    className={contactStyles.whatsapp}
                    src={whatsapp}
                    alt="Whatsapp"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
