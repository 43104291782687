import React from "react"
import Layout from "../components/layout"
import Hero from "../components/index/hero"
import "../styles/main.scss"
import About from "../components/index/about"
import Contact from "../components/index/contact"
import Comments from "../components/index/comments"
import Galery from "../components/index/galery"
import Fab from "../components/fab"

export default function Home() {
  return (
    <div>
      <Layout>
        <Fab />
        <Hero />
        <About />
        <Galery />
        <Contact />
        <Comments />
      </Layout>
    </div>
  )
}
