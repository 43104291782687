import React from "react"
import fabStyles from "./fab.module.scss"
import Wpp from "../assets/img/whatsapp_green.svg"

export default function Fab() {
  return (
    <div className={fabStyles.fab}>
      <a
        href="http://wa.me/+573233450951"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={Wpp} alt="Whatsapp" />
      </a>
    </div>
  )
}
