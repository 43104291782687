import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import News from "./news"
import aboutStyles from "./about.module.scss"
import arabesco1 from "../../assets/img/arabesco1.svg"
import arabesco2 from "../../assets/img/arabesco2.svg"
import menuPDF from "../../assets/menu_gyros.pdf"

export default function About() {
  const data = useStaticQuery(graphql`
    query AboutBg {
      image: file(relativePath: { eq: "black-wall2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage fluid={data.image.childImageSharp.fluid}>
      <News />
      <div className={aboutStyles.container} id="nosotros">
        <div className={aboutStyles.content}>
          <div className={aboutStyles.arabescosContainer}>
            <div className={aboutStyles.arabesco}>
              <img src={arabesco2} alt="Arabesco" />
            </div>
            <div className={aboutStyles.about}>
              <h1 className={aboutStyles.name}>Gyros & Bar BQ</h1>
              <p>
                Desde 1993, experimentando con los sabores tradicionales de
                nuestra tierra. Asados al carbón y platos típicos de la región,
                con ingredientes de calidad y un toque especial. Siempre
                dispuestos para que nuestros clientes disfruten lo típico, de
                una forma completamente diferente.
              </p>
              <a
                href="http://wa.me/+573233450951"
                rel="noopener noreferrer"
                className={aboutStyles.action}
                target="_blank"
              >
                RESERVAR
              </a>
              <div className={aboutStyles.mainArabesco}>
                <img src={arabesco1} alt="Arabesco" width="50%" />
              </div>
            </div>
            <div className={aboutStyles.rightArabesco}>
              <img src={arabesco2} alt="Arabesco" />
            </div>
          </div>
          <div className="horizontal-line"></div>
          <div id="menu">
            <h1 className={aboutStyles.menuTitle}>
              Algunos de nuestros favoritos
            </h1>
            <div className={aboutStyles.menu}>
              <div className={aboutStyles.row}>
                <div className={aboutStyles.product}>
                  <h3>Bandeja Paisa</h3>
                  <p>
                    Una bandeja paisa como se debe, deliciosos frijoles con
                    arroz, huevos, carne asada al carbón, tajada madura, arepa,
                    chorizo y aguacate. Una auténtica delicia.
                  </p>
                </div>

                <div className={aboutStyles.product}>
                  <h3>Costillas BBQ</h3>
                  <p>
                    600g de costilla de cerdo en cocción lenta, finalizada al
                    carbón con nuestra famosa salsa BBQ, acompañadas con papas,
                    arepa y ensalada.
                  </p>
                </div>
              </div>

              <div className={aboutStyles.row}>
                <div className={aboutStyles.product}>
                  <h3>Picada</h3>
                  <p>
                    Ya sea para comer solo o acompañado, un delicioso asado al
                    carbón con carne de res, cerdo, pollo, chorizo, costilla y
                    chicharrón. Acompañada con papas, arepas, patacón, guacamole
                    y salsa BBQ. Nunca decepciona.
                  </p>
                </div>

                <div className={aboutStyles.product}>
                  <h3>Maduro Asado con Queso</h3>
                  <p>
                    Una verdadera locura, un delicioso plátano maduro, suave,
                    dulce, perfecto, con queso y bocadillo.
                  </p>
                </div>
              </div>

              <div className={aboutStyles.row}>
                <div className={aboutStyles.product}>
                  <h3>Hamburguesa Gyros</h3>
                  <p>
                    Perfección, una deliciosa carne de res asada al carbón,
                    cubierta por vegetales, queso cheddar, tocineta y un
                    delicioso pan artesanal.
                  </p>
                </div>

                <div className={aboutStyles.product}>
                  <h3>Camarones Apanados</h3>
                  <p>
                    Un poco de mar, camarones tigre apanados, en su punto,
                    crocantes, acompañados con miel mostaza y patacón.
                  </p>
                </div>
              </div>

              <div className={aboutStyles.product}>
                <a
                  className={aboutStyles.menuFile}
                  href={menuPDF}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h3>Menú Completo...</h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
