import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import galeryStyles from "./galery.module.scss"

export default function Galery() {
  const data = useStaticQuery(graphql`
    query Images {
      images: allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid(maxWidth: 3080, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  return (
    <div id="galeria" className={galeryStyles.container}>
      <div className={galeryStyles.content}>
        <div className={galeryStyles.oneRow}>
          <div className={galeryStyles.card}>
            <Img
              className={galeryStyles.img}
              fluid={{
                ...data.images.nodes[0].childImageSharp.fluid,
                aspectRatio: 1 / 1,
              }}
              alt={data.images.nodes[0].name}
            />
            <h3 className={galeryStyles.text}>Costillas BBQ</h3>
          </div>
        </div>
        <div className={galeryStyles.twoRow}>
          <div className={galeryStyles.card}>
            <Img
              className={galeryStyles.img}
              fluid={{
                ...data.images.nodes[1].childImageSharp.fluid,
                aspectRatio: 1 / 1,
              }}
              alt={data.images.nodes[1].name}
            />
            <h3 className={galeryStyles.text}>Bandeja Paisa</h3>
          </div>
          <div className={galeryStyles.card}>
            <Img
              className={galeryStyles.img}
              fluid={{
                ...data.images.nodes[2].childImageSharp.fluid,
                aspectRatio: 1 / 1,
              }}
              alt={data.images.nodes[2].name}
            />
            <h3 className={galeryStyles.text}>Maduro Asado</h3>
          </div>
        </div>
      </div>

      <div className={galeryStyles.content}>
        <div className={galeryStyles.twoRow}>
          <div className={galeryStyles.card}>
            <Img
              className={galeryStyles.img}
              fluid={{
                ...data.images.nodes[3].childImageSharp.fluid,
                aspectRatio: 1 / 1,
              }}
              alt={data.images.nodes[3].name}
            />
            <h3 className={galeryStyles.text}>Picada</h3>
          </div>
          <div className={galeryStyles.card}>
            <Img
              className={galeryStyles.img}
              fluid={{
                ...data.images.nodes[4].childImageSharp.fluid,
                aspectRatio: 1 / 1,
              }}
              alt={data.images.nodes[4].name}
            />
            <h3 className={galeryStyles.text}>Hamburguesa Gyros</h3>
          </div>
        </div>
        <div className={galeryStyles.oneRow}>
          <div className={galeryStyles.card}>
            <Img
              className={galeryStyles.img}
              fluid={{
                ...data.images.nodes[5].childImageSharp.fluid,
                aspectRatio: 1 / 1,
              }}
              alt={data.images.nodes[5].name}
            />
            <h3 className={galeryStyles.text}>Chuzo Desgranado</h3>
          </div>
        </div>
      </div>

      <div className={galeryStyles.twoColumn}>
        <div className={galeryStyles.column}>
          <div className={galeryStyles.card}>
            <Img
              className={galeryStyles.img}
              fluid={{
                ...data.images.nodes[6].childImageSharp.fluid,
                aspectRatio: 1 / 1,
              }}
              alt={data.images.nodes[6].name}
            />
            <h3 className={galeryStyles.text}>Malteada de Fresa</h3>
          </div>
        </div>
        <div className={galeryStyles.column}>
          <div className={galeryStyles.card}>
            <Img
              className={galeryStyles.img}
              fluid={{
                ...data.images.nodes[7].childImageSharp.fluid,
                aspectRatio: 1 / 1,
              }}
              alt={data.images.nodes[7].name}
            />
            <h3 className={galeryStyles.text}>Ensalada de Pollo</h3>
          </div>
        </div>
      </div>
    </div>
  )
}
