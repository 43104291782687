import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import commentsStyles from "./comments.module.scss"

export default function Comments() {
  const data = useStaticQuery(graphql`
    query CommentsBg {
      image: file(relativePath: { eq: "black-wall2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage fluid={data.image.childImageSharp.fluid}>
      <div id="testimonios" className={commentsStyles.container}>
        <div className={commentsStyles.info}>
          <div>
            <p>
              "Recuerdo ir hace 20 años a la circunvalar a comer y ahora vuelvo
              después de 20 años y es un lugar que aún no pierde su estilo. La
              comida en una proporción interesante y los valores son muy acordes
              al producto. Parquear el carro es un poco complicado pero no
              imposible y la atención la hacen en un tiempo rápido y con muy
              buena atención. Definitivamente superó mis expectativas a pesar de
              tanto tiempo".
            </p>
            <p>- Julio C</p>
          </div>
          <div>
            <p>
              "Siempre que voy a Pereira mi parada obligada es GYROS & BAR BQ su
              comida es deliciosa y su atencion insuperable".
            </p>
            <p>- Alejandra C</p>
          </div>
          <div>
            <p>
              "Variedad de comidas de mar y tierra, comidas rápidas o a la
              carta. Un muy buen sitio para disfrutar y compartir con amigos y
              familia".
            </p>
            <p>- Carlos M</p>
          </div>
          <div className={commentsStyles.moreComments}>
            <a
              href="https://www.google.com/search?q=gyros+y+bbq&oq=gyros&aqs=chrome.4.69i59l3j69i57j0l3j69i60.3431j1j7&sourceid=chrome&ie=UTF-8#lrd=0x8e38873c406e9065:0x972a713b8f97776f,1,,,"
              rel="noopener noreferrer"
              target="_blank"
            >
              Más comentarios...
            </a>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
